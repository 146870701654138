import './styles/main.scss';
import _ from 'lodash';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import firebaseConfig from './firebaseConfig';
import OTIcon from './assets/ot-logo.png';

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const buttons = Object.values(document.getElementsByClassName('tablink'));

const resetTabs = () => {
  const tabs = Object.values(document.getElementsByClassName('tabcontent'));
  tabs.forEach((tab) => tab.style.display = 'none');
}

const activateBtn = (btnName) => {
  buttons.forEach((btn) => btn.classList.remove('active'));

  const btnToActivate = document.getElementById(btnName);
  btnToActivate.classList.add('active');
}

const defaultTab = document.getElementById('usiu');
defaultTab.style.display = 'block';

buttons.forEach((button) => {
  button.addEventListener('click', (value) => {
    const { target: { id }} = value;

    switch (id) {
      case 'usiu-btn':
        const usiu = document.getElementById('usiu');
        resetTabs();
        activateBtn(id);
        usiu.style.display = 'block';
        break;

      case 'raise-btn':
        const raise = document.getElementById('raise');
        resetTabs();
        activateBtn(id);
        raise.style.display = 'block';
        break;
      
      case 'made-btn':
        const made = document.getElementById('made');
        resetTabs();
        activateBtn(id);
        made.style.display = 'block';
        break;

      case 'paylend-btn':
        const paylend = document.getElementById('paylend');
        resetTabs();
        activateBtn(id);
        paylend.style.display = 'block';
        break;
    
      default:
        const usiuDefault = document.getElementById('usiu');
        resetTabs();
        activateBtn(id);
        usiuDefault.style.display = 'block';
        break;
    }
  });
});
